<template>
  <div v-if="isEnabled" ref="captcha" class="captcha-container" />
</template>

<script setup>
import { useField } from "vee-validate";

const captcha = ref(null);
const widgetId = ref(null);
const error = ref(null);

const config = useRuntimeConfig();
const sitekey = config.public["turnstileSitekey"];
const language = config.public["timeZone"];

const rules = () => !error.value || error.value;

const { value: token } = useField("captchaToken", rules);

const isEnabled = sitekey && turnstile;

onMounted(async () => {
  await nextTick();

  if (!isEnabled) return;

  turnstile.ready(() => {
    if (widgetId.value) return;
    widgetId.value = turnstile.render(captcha.value, {
      sitekey,
      language,
      "response-field-name": "captchaToken",
      callback: (t) => {
        token.value = t;
        error.value = null;
      },
      "error-callback": (e) => {
        error.value = e;
        token.value = null;
      },
    });
  });
});

defineExpose({
  reset: () => isEnabled && turnstile.reset(widgetId.value),
});
</script>
