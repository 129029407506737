<template>
  <div ref="formWrapperRef" :class="[
    'form-builder-section',
    {'form-duplication': blok?.formType === 'aide-a-domicile' || blok?.formType === 'REQUEST_CALL'}
  ]">
    <div class="section-inner">
      <template v-if="!isSent">
        <h2 v-if="blok?.title" class="title size-xl">{{ blok.title }}</h2>
        <p v-if="blok?.description" class="intro size-2xs">{{
          useStringParams(blok.description, {
            facilityName: facility?.commercialName,
          })
        }}</p>
        <p class="size-2xs">{{ useMicroCopy("form.required.error.label") }}*</p>
        <Form v-show="!sendError" v-slot="{ errors }" class="form" novalidate @submit="handleSubmit">
          <div
            v-for="(field) in blok?.fields"
            :key="field._uid"
            class="form-builder-item size-2xs"
          >
            <StoryblokComponent
              :blok="field"
              :is-myself="isMyself"
              :updated-value="selectUpdatedValue"
              @is-disabled-submit="isTimeSlotNull = true"
              @is-enabled-submit="isTimeSlotNull = false"
              @is-myself="onMyselfChecked"
              @select-value="onSelectValue"
            />
          </div>
          <FormCaptcha
            v-if="turnstileSitekey && isReady"
            ref="captcha"
            class="captcha"
          />
          <SimpleButton
            class="submit-button"
            solid
            xl
            :disabled="isSending || !!errors['captchaToken'] || isTimeSlotNull"
          >
            {{ blok?.submit_label }}
          </SimpleButton>
        </Form>
        <FormResponse
          v-if="sendError"
          :title="useMicroCopy('form.err.captcha.title')"
          :description="useMicroCopy(`${sendError.message}`)"
          :cta-label="useMicroCopy('form.err.cta.label')"
          errored
          @click="resetForm"
        />
      </template>
      <FormResponse
        v-else
        :title="useMicroCopy('form.confirm.title')"
        :description="useMicroCopy('form.confirm.description')"
        :cta-label="useMicroCopy('form.confirm.cta.label')"
        @click="resetForm"
      />
    </div>
  </div>
</template>

<script setup>
import { Form } from "vee-validate";
import { useTrackingStore } from "@/stores/tracking";
import countries from "i18n-iso-countries";

const trackingStores = useTrackingStore();

const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  story: {
    type: Object,
    required: true,
  },
  facility: {
    type: Object,
    required: false,
    default: () => {},
  },
  isInPopin: {
    type: Boolean,
    required: false,
    default: () => false,
  }
});
const config = useRuntimeConfig();
const route = useRoute();
const turnstileSitekey = config.public["turnstileSitekey"];
const isSent = ref(false);
const captcha = ref(null);
const isReady = ref(false);
const formWrapperRef = ref(null);
const isTimeSlotNull = ref(false);
const isMyself = ref(false);
const selectUpdatedValue = ref(null);

const query = gql`
  mutation createForm($formData: FormInput!) {
    firstForm: insertForm(formData: $formData) {
      uuid
      locale
      tracking
      formType
      facilityUniqueKey
      firstName
      lastName
      email
      phone
      timeSlot
      preferredDay
      request
      cguContent
      content
      crm
      createdAt
      updatedAt
      message
    }
  }
`;

const {
  mutate: sendData,
  error: sendError,
  onDone: onSendSuccess,
  onError: onSendError,
  loading: isSending,
} = useMutation(query);

const tracking = (data) => {
  trackingStores.sendTrackingData(data);
};

const hasHubspotCookie = ref(false);

const handleSubmit = async(values) => {
  const ipAddress = await fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then((response) => {
      return response.ip;
    });

  const queryParamsMetadata = route.query;

  const hiddenFormFields = props.blok.fields?.filter(item => item.type === 'hidden');

  hiddenFormFields?.map((item) => {
    if(item.id === 'EstablishmentCodeSector') {
      item.value = props.facility?.percevalSectorName ?? null;
    }
    if(item.id === 'IpAddress') {
      item.value = ipAddress ?? null;
    }
    if(item.id === 'EstablishmentId') {
      item.value = props.facility?.srcLocalId ?? null;
    }
  });

  const hiddenFormMetadata = hiddenFormFields?.reduce((obj, item) => Object.assign(obj, { [item.id]: item.value }), {});

  const notificationsMetadata = {
    "notification" : {
      "internal" : props.blok?.internal_notification ?? false,
      "lead" : props.blok?.external_notification ?? false
    } 
  };

  const defaultMetadata = {
    "IpAddress": ipAddress ?? null,
    "url": config.public["baseURL"] + route.fullPath ?? null,
    "facilityUniqueKey": props.facility?.facilityUniqueKey ?? null,
    "facilityTypeGroupKey": props.facility?.facilityTypeGroupKey ?? null,
    "srcLocalId": props.facility?.srcLocalId ?? null,
    "storyID": props.story?.uuid ?? null,
    "city": props.facility?.city ?? null,
  };

  const cookieMetadata = {
    "hubspotutk": getCookie('hubspotutk')
  };

  const fieldsMetadata = props.blok.hidden_fields?.reduce((obj, item) => Object.assign(obj, { [item.id]: item.value }), {});

  const formattedMetadata = Object.assign({}, defaultMetadata, fieldsMetadata, queryParamsMetadata, notificationsMetadata, hasHubspotCookie.value ? cookieMetadata : "");

  const formattedFormdata = Object.assign({}, values, hiddenFormMetadata);

  delete formattedFormdata["IdTypeServiceDom"];

  const valuesToSend = {
    formData: {
      "formType": props.blok?.formType,
      "crm" : props.blok?.crm,
      "locale" : countries.getName(config.public["country"], "en").toUpperCase(),
      "content": {
        "form": formattedFormdata,
        "metadata": formattedMetadata,
      },
      "captchaToken": formWrapperRef.value?.querySelector('[name="captchaToken"]')?.value
    }
  };
  sendData(valuesToSend);
  captcha.value?.reset();
  window.scrollTo(0, 0);
};

onSendSuccess(() => {
  isSent.value = true;
  tracking({
    event: 'click.action',
    click: useSnakeCaseFilter(props.blok?.submit_label),
    click_chapter1: props.isInPopin ? "popin" : "inline",
    click_chapter2: 'CTA',
    form_name: useSnakeCaseFilter(props.blok?.form_ref),
    form_type: props.isInPopin ? "popin" : "inline",
  })
});

onSendError((error) => {
  console.error(error);
});

const resetForm = () => {
  isSent.value = false;
  sendError.value = null;
};

const getCookie = (name) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '');
}

const onMyselfChecked = (myselfValue) => {
  isMyself.value = (props.blok?.formType === "aide-a-domicile" || props.blok?.formType === "REQUEST_CALL") && myselfValue === true ? true : false;
}

const onSelectValue = (value) => {
  selectUpdatedValue.value = value;
}

onMounted(() => {
  isReady.value = true;
  hasHubspotCookie.value = getCookie('hubspotutk') ? true : false;
});

useHead({
  bodyAttrs: {
    class: "has-relative-main",
  },
  style: [
    {
      media: "screen",
      innerHTML: `
        .form-builder-item.select-opened {
          z-index: 1;
        }
      `,
    },
  ],
});
</script>

<style lang="scss" scoped>
.form-builder-section:not(.section) {
  width: 100%;
}

.title {
  text-align: center;
  margin-bottom: 1rem;
}
.intro {
  margin-bottom: 3rem;
  text-align: center;

  @include for-tablet-landscape-up {
    margin-bottom: 4rem;
  }
}
.form {
  margin-top: 1rem;

  @include for-tablet-landscape-up {
    margin-top: 1.5rem;
  }
}
.form-builder-item {
  margin-bottom: 0.625rem;

  &:has(.form-select.is-open),
  &:has(.datepicker-wrapper.is-open) {
    z-index: 1;
  }
}
.form-response {
  width: auto;
}
.submit-button {
  margin: 2rem auto 0;
}
</style>
